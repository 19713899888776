import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SectionTitle = ({ slogan, title, classes, img, withHr }) => {
  return (
    <div className={`section-title ${classes ? classes : ""}`}>
      {/* <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      > */}
      <span
        className="pre-title"
        dangerouslySetInnerHTML={{ __html: slogan }}
      ></span>
      {/* </ScrollAnimation> */}

      <div className="inner-section-title">
        <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
        {img && <img className="image-1" src={img} alt="About Main Thumb" />}
      </div>
      {withHr && <hr />}
    </div>
  );
};
export default SectionTitle;
