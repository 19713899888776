import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li >
                <Link to="/inicio">Inicio</Link>
            </li>

            {/* <li>
                <Link to="/cooperativa">Cooperativa</Link>
            </li> */}
            
            <li>
                <Link to="/cursos">Cursos</Link>
            </li>
            
            <li>
                <Link to="/contacto">Contacto</Link>
            </li>
        </ul>
    )
}
export default Nav;
