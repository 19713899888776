import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ContactUsForm from "../../components/contact/ContactUsForm";

const ContactUs = () => {
  return (
    <>
      <SEO title="Contáctanos" />
      <Layout>
        <BreadcrumbOne
          title="Contáctanos"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contáctanos"
          id="contact"
        />
        <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row mt--20">
              <div className="col-lg-12">
                <div className="contact-info pr_lg--0 pr_md--0 pr_sm--0">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      {/* <div className="contact-address-card-1 email"> */}
                      {/* <div className="inner"> */}
                      {/* <div className="icon">
                                                        <i className="icon-mail-open-line"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title">Correo electrónico</h6>
                                                        <p><a href="mailto:adocsecretaria@gmail.com">adocsecretaria@gmail.com</a></p>
                                                    </div> */}

                      {/* </div> */}
                      {/* </div> */}
                      <img src="/images/contact/mail.png" alt="mail" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      {/* <div className="contact-address-card-1 phone">
                        <div className="inner">
                          <div className="icon">
                            <i className="icon-Headphone"></i>
                          </div>
                          <div className="content">
                            <h6 className="title">Teléfono</h6>
                            <p>
                              <a href="tel:095750717">095750717</a>
                            </p>
                          </div>
                        </div>
                      </div> */}

                      <img src="/images/contact/telefono.png" alt="telefono" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                                <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" />
                            </div> */}
            </div>
          </div>
        </div>

        {/* <div className="edu-contact-map-area edu-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="google-map alignwide">
                  <iframe
                    title="Google Map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2868.1351844315823!2d-56.19218990728812!3d-34.90985882283117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc951e6a6b2031c03!2sInstituto%20Italiano%20de%20Cultura%20de%20Montevideo!5e0!3m2!1sen!2suy!4v1658790160885!5m2!1sen!2suy"
                    height="500"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </>
  );
};
export default ContactUs;
